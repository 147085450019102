<template>
    <div>
        <v-row justify="center">
            <v-col cols="10" md="10" class="text-center">
                <h3 class="mb-4">Security</h3>
                <p>
                    Settings and recommendations to help you keep your account
                    secure
                </p>
                
            </v-col>
           


            <v-col cols="10" md="10">
                <v-data-table :headers="headers" :items="list" :search="search" hide-actions  class="elevation-1" color="primary" >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES DROITS ACCESS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="dialog=true">Ajouter</v-btn>
              

                <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Formulaire</v-card-title>
                        <v-card-text>

                            <v-select :items="list_droit_fenetre" v-model="selectfenetre" item-value="id" dense outlined label="Liste des monnaire">
                                <template slot="selection" slot-scope="data">
                                    {{ data.item.libelle_fenetre }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.libelle_fenetre }}
                                </template>

                            </v-select>
                           
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog2" max-width="400">

                    <v-card>
                        <v-card-title class="text-h5">Suppression</v-card-title>
                        <v-card-text>
                            <h3> Voulez vous vraiment supprimer blacklist ?</h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog2=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItem()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>

                </v-dialog>

            </v-toolbar>
        </template>
        <template v-slot:item.statut="{ item }">
            <v-switch v-model="item.status" color="primary" :label="item.statut==1 ? 'Activer' : 'Desactiver'" @change="changStatus(item)"></v-switch>
        </template>

        <v-dialog v-model="dialog2" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Formulaire</v-card-title>
                <v-card-text>
              </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog=false">Annuller</v-btn>
                    <v-btn color="blue darken-1" text @click="save()">Confirmer</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
       



        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detailsup(item)">
                <v-icon color="red" large class="mr-2">
                    mdi-delete
                </v-icon>
            </v-btn>
            <v-btn icon @click="getItem(item)">
                <v-icon color="teal" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>

        </template>



    </v-data-table>
            </v-col>


           
        </v-row>

    
       
  
    </div>
</template>
<script>
import EInfoCard from '@/components/card/BasicInfoCard'
import api from '@/serviceApi/apiService'
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Account Security'
    },
    components: {
        EInfoCard
    },
    data() {
        return {
            dialog: false,
        dialog2: false,
        search: "",
        list_droit_fenetre: [],
        selectfenetre: null,
            headers: [
                {
                    text: 'Action',
                    align: 'start',
                    sortable: false,
                    divider: true,
                    value: 'actions'
                    
                },
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    divider: true,
                    value: 'id'

                },
                {
                    text: 'Libelle Menu',
                    align: 'start',
                    sortable: false,
                    divider: true,
                    value: 'droit_fenetre.libelle_fenetre'
                },
                { text: 'Compte Admin', 
                align: 'start',
                sortable: false,
                divider: true,
                value: 'compte_admin.nom_prenom'

                },
                { text: 'Statut',
                align: 'start',
                sortable: false,
                divider: true,
                value: 'statut' },
                
            ],
            list: [],
            list2: [],
            etat: false,
            id: null,
            compte_admin_id : null,

            imgOne: require('@/assets/images/svg/ui.svg'),
            imgTwo: require('@/assets/images/svg/log-in.svg'),
            imgThree: require('@/assets/images/svg/contact.svg'),
         
        }
    },
    created() {
        this.readAll();
        this.readAll_fenetre();
    },
    methods:{

        readAll: async function () {
           
            const data = await api.readAll('backoffice/only-droit-access/'+this.$route.params.id);
            this.list2 = data;

            for (let i = 0; i < this.list2.length; i++) {
                this.list2[i]['status'] =this.list2[i].statut == 1 ? true : false; 
            }
            this.list = this.list2;

        },
        readAll_fenetre: async function () {
            let fd = new FormData();
            const data = await api.createUpdatedata('backoffice/all-droit-fentre', fd);
            this.list_droit_fenetre = data;
        },
        save: async function () {
            let fd = new FormData();
            fd.append('droit_fenetre_id', this.selectfenetre);
            fd.append('compte_admin_id', this.$route.params.id);
            if(this.etat==false){
                const data = await api.createUpdatedata('backoffice/add-droit-access', fd);
                if(data.status==true){
                    this.showAlert(data.message, 'success');
                    this.clearItem();
                }else{
                    this.showAlert(data.message, 'error');
                }
            }else{
                const data = await api.createUpdatedata('backoffice/update-droit-access/'+this.id, fd);
                if(data.status==true){
                    this.showAlert(data.message, 'success');
                    this.dialog = false;
                }else{
                    this.showAlert(data.message, 'error');
                }
                    
            
            }
                
            this.readAll();
           
        },
        getItem(item) {
            this.etat = true;
            this.id = item.id;
            this.selectfenetre = item.droit_fenetre.id;
            this.compte_admin_id  = item.compte_admin_id ;
            this.dialog = true;
        },
        async changStatus(item) {
           
          const data= await  api.readAll('backoffice/etat-droit-access/'+item.id);
            if(data.status==true){
                this.showAlert(data.message, 'success');
                this.readAll();
            }else{
                this.showAlert(data.message, 'error');
            }
            this.readAll();
        },
        clearItem: function () {
            this.etat = false;
            this.id = null;
            this.selectfenetre = null;
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                 position: 'top-end',
                 icon: txticone,
                 title: textmessage,
                 showConfirmButton: false,
                 timer: 1500
             })
         },


    }
}
</script>
